export default {
    SET_STATE: 'SET_STATE',
    LOGIN: 'LOGIN',
    REGISTER: 'REGISTER',
    TWEET: 'TWEET',
    GET_TWEETS: 'GET_TWEETS',
    LIKE_TWEET: 'LIKE_TWEET',
    BOOKMARK: 'BOOKMARK',
    GET_TWEET: 'GET_TWEET',
    GET_ACCOUNT: 'GET_ACCOUNT',
    GET_USER: 'GET_USER',
    GET_BOOKMARKS: 'GET_BOOKMARKS',
    UPDATE_USER: 'UPDATE_USER',
    RETWEET: 'RETWEET',
    DELETE_TWEET: 'DELETE_TWEET',
    FOLLOW_USER: 'FOLLOW_USER',
    EDIT_LIST: 'EDIT_LIST',
    CREATE_LIST: 'CREATE_LIST',
    DELETE_LIST: 'DELETE_LIST',
    GET_LISTS: 'GET_LISTS',
    LOG_OUT: 'LOG_OUT',
    GET_LIST: 'GET_LIST',
    GET_TREND: 'GET_TREND',
    SEARCH: 'SEARCH',
    TREND_TWEETS: 'TREND_TWEETS',
    ADD_TO_LIST: 'ADD_TO_LIST',
    GET_FOLLOWERS: 'GET_FOLLOWERS',
    GET_FOLLOWING: 'GET_FOLLOWING',
    SEARCH_USERS: 'SEARCH_USERS',
    WHO_TO_FOLLOW: 'WHO_TO_FOLLOW',
    GET_CONVERSATIONS: 'GET_CONVERSATIONS',
    START_CHAT: 'START_CHAT',
    GET_SINGLE_CONVERSATION: 'GET_SINGLE_CONVERSATION'
}